/* eslint-disable no-bitwise */
/* eslint-disable no-lonely-if */
import _ from 'lodash'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export default function LossOverOil(
  dataArr,
  yieldArr,
  name,
  startTime,
  endTime
) {
  let tempValueStack = []
  let nowDate = dayjs(endTime)
  const prevday = dayjs(startTime)
  let indexDate = nowDate.diff(prevday, 'day')

  if (dataArr?.length === 0) {
    while (indexDate >= 0) {
      tempValueStack.push({
        x: dayjs(nowDate).format('YYYY-MM-DD'),
        y: 0,
      })
      indexDate -= 1
      nowDate = dayjs(nowDate).subtract(1, 'day').format('YYYY-MM-DD')
    }
  } else {
    _.map(dataArr, (dataItem, index) => {
      //console.log('dataaa', indexDate)
      while (
        dayjs(dataItem?.date || dataItem?.Last).format('YYYY-MM-DD') !==
          dayjs(nowDate).format('YYYY-MM-DD') &&
        indexDate >= 0
      ) {
        tempValueStack.push({
          x: dayjs(nowDate).format('YYYY-MM-DD'),
          y: 0,
        })
        indexDate -= 1
        nowDate = dayjs(nowDate).subtract(1, 'day').format('YYYY-MM-DD')
      }
      indexDate -= 1
      nowDate = dayjs(dataItem?.date || dataItem?.Last)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      if (name === 'OilLossOverFiber') {
        //tempValueStack.push(dataItem.OilLossFiber)

        let checkValue =
          (parseFloat(dataItem[index]?.OilLossFiber) * 751.879699) /
          parseFloat(yieldArr[index]?.PalmUsed)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.OilLossFiber === null
              ? 0
              : dataItem?.OilLossFiber >= 2000 || checkValue < 4.5
              ? 0
              : (checkValue - parseFloat(dataItem?.OilLossFiber)).toFixed(2),
        })
        console.log(
          'yieldArr[index]?.PalmUsed',
          yieldArr[index]?.PalmUsed,
          checkValue,
          dataItem[index]?.date,
          yieldArr[index]?.date
        )
      } else if (name === 'OilLossDecanter') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.OilLossDecanter === null
              ? 0
              : dataItem?.OilLossDecanter >= 2000
              ? 0
              : parseFloat(dataItem?.OilLossDecanter).toFixed(2),
        })
      } else if (name === 'OilLossWastewater') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.OilLossWastewater === null
              ? 0
              : dataItem?.OilLossWastewater >= 2000
              ? 0
              : parseFloat(dataItem?.OilLossWastewater).toFixed(2),
        })
      } else if (name === 'PalmUsed') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.PalmUsed === null
              ? 0
              : dataItem?.PalmUsed >= 2000
              ? 0
              : parseFloat(dataItem?.PalmUsed).toFixed(2),
        })
      } else if (name === 'CPO') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.CPO === null
              ? 0
              : dataItem?.CPO >= 2000
              ? 0
              : parseFloat(dataItem?.CPO).toFixed(2),
        })
      } else if (name === 'Yield') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.Yield === null
              ? 0
              : dataItem?.Yield >= 2000
              ? 0
              : parseFloat(dataItem?.Yield).toFixed(2),
        })
      } else if (name === 'Total 1,2') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.GenMeter['Total 1,2'].length === 0
              ? 0
              : parseFloat(dataItem?.GenMeter['Total 1,2']).toFixed(2),
        })
      } else if (name === 'มิเตอร์ก๊าซที่ใช้ผลิต') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต.length === 0
              ? 0
              : parseFloat(dataItem?.MeterGas?.มิเตอร์ก๊าซที่ใช้ผลิต).toFixed(
                  2
                ),
        })
      } else if (name === 'QuantityPalm') {
        //console.log("fuel ");

        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.QuantityPalm.length === 0 || dataItem?.QuantityFiber
              ? 0
              : parseFloat(dataItem?.QuantityPalm).toFixed(2) +
                parseFloat(dataItem?.QuantityFiber).toFixed(2),
        })
      } else if (name === 'ProductE') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.date).format('YYYY-MM-DD'),
          y:
            dataItem?.ProductE.length === 0
              ? 0
              : parseFloat(dataItem?.ProductE).toFixed(2),
        })
      } else if (name === 'SumSteam') {
        //tempValueStack.push(dataItem.OilLossFiber)
        tempValueStack.push({
          x: dayjs(dataItem?.Last).format('YYYY-MM-DD'),
          y: parseFloat(dataItem?.OilBasis).toFixed(2),
        })
      }
    })
  }

  //console.log('TEMP ', tempValueStack)
  return tempValueStack
}
