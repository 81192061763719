/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { th } from 'date-fns/locale'
import { format, addMonths } from 'date-fns'
// components
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import DisplayData from './component/DisplayData'
import SelectLogStation from './component/SelectLogStation'
import { DayPicker } from 'react-day-picker'
//import GraphPrediction from './component/graphPrediction'

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import _, { add, set } from 'lodash'
import 'react-day-picker/dist/style.css'

export default function SummarizeList() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const form = useSelector((state) => state.form)
  const formInput = useSelector((state) => state.formInput)
  const params = useParams()
  const stations = useSelector((state) => state.station)
  const logQC = useSelector((state) => state.log)
  const summarizes = useSelector((state) => state.summarize)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const start = searchParams.get('start')
  const end = searchParams.get('end')
  const currentDate = new Date()
  const [startTime, setStartTime] = useState(
    start || format(addMonths(currentDate, -1), 'yyyy-MM-dd')
  )
  const [endTime, setEndTime] = useState(
    end || format(currentDate, 'yyyy-MM-dd')
  )
  const [enableFilter, setEnableFilter] = useState(false)

  const defaultSelected = {
    from: new Date(startTime),
    to: new Date(endTime),
  }

  const [range, setRange] = useState(defaultSelected)

  useEffect(() => {
    setEnableFilter(false)
    if (range?.from) {
      if (range.to) {
        setStartTime(format(range.from, 'yyyy-MM-dd'))
        setEndTime(format(range.to, 'yyyy-MM-dd'))
        setEnableFilter(true)
      }
    }
  }, [range])

  useEffect(() => {
    dispatch(actions.formInputSummarize(startTime, endTime)).then(() =>
      setIsLoading(true)
    )
    dispatch(actions.stationAll())
    dispatch(actions.logSummarize(startTime, endTime))
    dispatch(actions.formGet('64e8336ecc9d3e0d64e80174'))

    dispatch(actions.summarizeAll())
    return () => {}
  }, [params])

  //console.log('stations', stations)
  const stationQC = _.find(
    stations.arr,
    (each) => each._id === '641933db73942c001101cb32'
  )
  const stationBiogas = _.find(
    stations.arr,
    (each) => each._id === '63c668b1ad97c655441b7f88'
  )
  const stationBiomass = _.find(
    stations.arr,
    (each) => each._id === '647f4a8cc359d800123681e1'
  )

  //console.log('Form ', formInput)
  //console.log('Form Input', yieldAll)
  //console.log('Form ALLLL ', oilLossAll[0])

  console.log('loss Fiber', logQC)
  const findData = (id) => {
    if (id === '6555dbcf88676d55586ada5a') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '6549bcbd956d160012355d59') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '655472c2de434133acd27176') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '655473b5de434133acd271f9') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '65781176ff1bdc001256a060') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '655473d5de434133acd27212') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '659eb111bde8bf4e544f25cf') {
      return _.find(stationQC?.variables, (each) => each?._id === id)
    } else if (id === '65b0ca8f9985d6174c098c9e') {
      return _.find(stationBiogas?.variables, (each) => each?._id === id)
    } else if (id === '65b0cc749985d6174c098de5') {
      return _.find(stationBiogas?.variables, (each) => each?._id === id)
    } else if (id === '65b0cdd09985d6174c098f47') {
      return _.find(stationBiomass?.variables, (each) => each?._id === id)
    } else if (id === '65b22465fb3863618455dee0') {
      return _.find(stationBiomass?.variables, (each) => each?._id === id)
    } else if (id === '65b2252bfb3863618455e05a') {
      return _.find(stationBiomass?.variables, (each) => each?._id === id)
    } else {
      return null
    }
  }

  let footer = <p>กรุณาเลือกวันที่ต้องการดูข้อมูล</p>
  if (range?.from) {
    if (!range.to) {
      footer = <p>กรุณาเลือกช่วงวัน</p>
    } else if (range.to) {
      footer = (
        <p>
          ตั้งแต่ {startTime} ถึง {endTime}
        </p>
      )
    }
  }

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การสรุปผลการดำเนินงาน
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              รายการสรุป
            </h2>
            <div className="flex gap-3 items-center justify-center">
              <Popover placement="auto">
                <PopoverTrigger>
                  <Button>{footer}</Button>
                </PopoverTrigger>
                <PopoverContent w={'fit-content'}>
                  <div>
                    <DayPicker
                      mode="range"
                      captionLayout="dropdown-buttons"
                      fromYear={2015}
                      toYear={new Date().getFullYear()}
                      onSelect={setRange}
                      defaultMonth={new Date(startTime)}
                      selected={range}
                      locale={th}
                      numberOfMonths={2}
                    />
                  </div>
                  <div className="flex w-full py-3 justify-center px-2">
                    <Button
                      onClick={() => {
                        window.location.href = `/summarize?start=${startTime}&end=${endTime}`
                      }}
                      width={'full'}
                      disabled={!enableFilter}
                    >
                      เลือกช่วงเวลา
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 w-full">
        <div className="overflow-x-auto">
          <div>
            {stationQC?.variables && (
              <DisplayData
                startTime={startTime}
                endTime={endTime}
                oilLoss={findData('6555dbcf88676d55586ada5a')}
                yieldCpo={findData('659eb111bde8bf4e544f25cf')}
                decanterLoss={findData('655473b5de434133acd271f9')}
                wasteLoss={findData('655473d5de434133acd27212')}
                QCLog={SelectLogStation(
                  logQC?.arr[0],
                  '641933db73942c001101cb32'
                )}
                oilLossAll={formInput?.arr[0]}
                yieldAll={formInput?.arr[1]}
                biogasAll={formInput?.arr[2]}
                BiomassLog={SelectLogStation(
                  logQC?.arr[0],
                  '647f4a8cc359d800123681e1'
                )}
                totalElectric={findData('65b0ca8f9985d6174c098c9e')}
                gasUsed={findData('65b0cc749985d6174c098de5')}
                findSumSteam={logQC?.arr[1]}
                sumSteam={findData('65b0cdd09985d6174c098f47')}
                fuelUsed={findData('65b22465fb3863618455dee0')}
                AllFuel={formInput?.arr[3]}
                electricGenForm={formInput?.arr[4]}
                electricGen={findData('65b2252bfb3863618455e05a')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
