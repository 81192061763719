import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import GraphPrediction from '../prediction/component/graphPrediction'

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { Button, Link as LinkUI, Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'

export default function DisplayGraphPrediction() {
  const dispatch = useDispatch()
  const params = useParams()
  const sm = useSelector((state) => state.summarize)
  const stations = useSelector((state) => state.station)
  const predictions = useSelector((state) => state.prediction)
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const allLogs = useSelector((state) => state.log)
  const stationId = '647ebd41fe2c4a21dabe6823'

  useEffect(() => {
    if (stationId && isFetchNewOne === true) {
      if (isFirstTime === true) {
        dispatch(actions.logLastestTwenty(stationId))
          .then(() => {
            setIsLoading(true)
            setisFetchNewOne(false)
            setIsFirstTime(false)
          })
          .catch((err) => {
            alert('Error on Fetching log')
          })

        dispatch(actions.summarizeGet(params.summarizeId))
        dispatch(
          actions.predictionVarDetail({
            summarizedId: params.summarizeId,
            varDetailId: params.id,
          })
        )
        dispatch(actions.stationAll())
      } else {
        //setTickAmount(10)
        dispatch(actions.logLastestTwenty(stationId))
        dispatch(
          actions.predictionVarDetail({
            summarizedId: params.summarizeId,
            varDetailId: params.id,
          })
        )
        setisFetchNewOne(false)
      }
    }
    return () => {}
  }, [isFetchNewOne, stationId])

  const findStation = (id) => {
    const data = _?.find(stations?.arr, (each) => each?._id === id)
    return data
  }

  const findSensor = (id) => {
    let data = {}
    _.map(stations?.arr, (st, key) => {
      _.map(st.sensors, (ss) => {
        if (ss?._id === id) {
          data = ss
        }
      })
    })
    return data
  }

  const findPrediction = (id) => {
    return _.find(predictions?.arr, (each) => each?.sourceId === id)
  }

  const foundVarDetail = _.find(
    sm?.varDetail,
    (varDetail) => varDetail?._id === params.id
  )

  const foundVarDetailIndex = _.findIndex(
    sm?.varDetail,
    (varDetail) => varDetail?._id === params?.id
  )

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                การสรุปผลการดำเนินงาน
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans">
                {`เซนเซอร์ที่เกี่ยวข้องกับ ${sm?.variable?.name}`}
              </h2>
            </div>
          </div>
          <div>
            {sm?.group ? (
              <div className="my-2 mx-2 text-lg font-bold">
                {`กลุ่มที่ ${foundVarDetailIndex + 1} ${
                  foundVarDetail?.groupName
                }`}
              </div>
            ) : (
              <div className="my-2 mx-2 text-lg font-bold">
                {'เซนเซอร์ทั้งหมดของ'} {sm?.variable?.name}
              </div>
            )}
            <Link to={'/summarize/detail'}>
              <Button variant="outline" size="sm" marginBottom={3}>
                กลับ
              </Button>
            </Link>
            <div className="grid p-4  w-full bg-white rounded-sm border-black border-2">
              <div className="grid-cols-2 p-4 w-full ">
                {_.map(foundVarDetail?.attribute, (attribute, index) => (
                  <div>
                    {/* <h2>{attribute?.sourceName}</h2> */}
                    <GraphPrediction
                      key={index}
                      chartData={findSensor(attribute.sourceId)}
                      data={allLogs}
                      dataPrediction={findPrediction(attribute?.sourceId)}
                      // graphMax={graphMax}
                      // graphMin={graphMin}
                      // setGraphMax={setGraphMax}
                      // setGraphMin={setGraphMin}
                      // setTickAmount={setTickAmount}
                      // setValueFormat={setValueFormat}
                      // tickAmount={tickAmount}
                      isLoading={isLoading}
                      //isSuccess={isSuccess}
                      stationData={findStation(attribute?.station)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
