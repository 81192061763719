/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Chart, { Props } from 'react-apexcharts'
import dayjs from 'dayjs'
import fetchingData from './FetchingData'

//import FilterCalendarDate from '../Functions/FilterCalendarDate'
import FindControls from './FindControls'
import { useTranslation } from 'react-i18next'
import SpinnerLoading from 'components/Loading/SpinnerLoading'

export default function GraphPrediction({
  stationData,
  chartData,
  dataPrediction,
  data,
  isLoading,
}) {
  const [valueFormat, setValueFormat] = useState('HH:mm')
  const [tickAmount, setTickAmount] = useState(7)
  const { t } = useTranslation()

  const fetcedData = fetchingData(data?.arr, chartData?._id)
  const chartMargin =
    ((chartData?.controlRange?.upper - chartData?.controlRange?.lower) / 100) *
    40

  //Default Options
  const options = {
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => dayjs(value).locale('th').format(valueFormat),
      },
      tickAmount,
      //min: undefined,
      //max: undefined,
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return parseFloat(value).toFixed(2)
        },
      },
      // min: (min) => min,
      // max: (max) => max,
      max: chartData?.controlRange?.upper + chartMargin,
      min: chartData?.controlRange?.lower - chartMargin,
      // (min) =>
      //   chartData?.fixedYAxis?.enable ? chartData?.fixedYAxis?.lower : min,
      // max: (max) =>
      //   chartData?.fixedYAxis?.enable ? chartData?.fixedYAxis?.upper : max,
      // tickAmount: 5,
    },
    // Line of Alert

    annotations: chartData?.controlRange?.enable
      ? {
          yaxis: [
            {
              y: chartData?.controlRange?.upper,
              y2: chartData?.controlRange?.lower,
              fillColor: '#FF8000',
              borderColor: '#FF8000',
              label: {
                show: true,
                text: `High Control  ${chartData?.controlRange?.upper}`,
                style: {
                  color: '#fff',
                  background: '#FF8000',
                },
              },
            },
            {
              y: chartData?.controlRange?.lower,
              borderColor: '#FF8000',
              width: '100%',
              opacity: 0.1,

              label: {
                show: true,
                text: `Low Control ${chartData?.controlRange?.lower}`,
                style: {
                  color: '#fff',
                  background: '#FF8000',
                },
              },
            },
          ],
        }
      : {},

    legend: {
      show: true,
      customLegendItems: ['Raw Data', 'Prediction'],
      inverseOrder: true,
    },

    stroke: {
      width: [3, 3],
      curve: 'straight',
      dashArray: [0, 0],
    },
    markers: {
      hover: {
        sizeOffset: 5,
      },
    },
    chart: {
      //  width: '100%',
      // height: '400px',
      type: 'rangeArea',
      animations: {
        speed: 500,
      },
    },
    colors: ['#160aff', '#65cde5'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: [1, 1.2],
    },

    tooltip: {
      x: {
        show: true,
        formatter: (value) => {
          if (dayjs(value).isValid()) {
            return dayjs(value).locale('th').format(valueFormat)
          } else {
            return value
          }
        },
      },
    },
  }

  //Rendered Series
  const series = [
    {
      type: 'line',
      name: 'Raw Data',
      data: _.reverse(fetcedData),
    },
    // {
    //   type: 'line',
    //   name: 'prediction',
    //   data: _.reverse(dataPrediction?.results || []),
    // },
  ]

  return isLoading ? (
    <div>
      <h4 className="font-bold text-xl font-sans">{chartData?.name}</h4>
      ค่า Control Range : {chartData?.controlRange?.lower} -{' '}
      {chartData?.controlRange?.upper} {chartData?.valueUnit}
      <Chart
        type="rangeArea"
        series={series}
        options={options}
        width="100%"
        height="400px"
      />
    </div>
  ) : (
    <SpinnerLoading />
  )
}
