/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from 'react'
import _, { each } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../redux/actions'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker'
import CompressIcon from '@mui/icons-material/Compress'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { Button, Badge, Link as LinkUI, Box } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import StaticNumberDisplay from '../component/StaticNumberDisplay'
import NumberBoxs from './NumberBoxs'
import StatusRangeControl from './StatusRangeControl'
import { GaugeType } from '../../../config/ComponentType'
import fetchingData from './FetchingData'
import { auto } from '@popperjs/core'
import { relative } from 'path'

export default function DisplayComponentPredict({
  summarizes,
  stations,
  isLoading,
}) {
  const dispatch = useDispatch()
  const station = useSelector((state) => state.station)
  const [selectStation, setSelectStation] = useState()

  useEffect(() => {
    setSelectStation(stations)
  })

  const iconSelect = (name, status) => {
    if (
      name === 'หม้อนึ่งที่ 1' ||
      name === 'หม้อนึ่งที่ 2' ||
      name === 'หม้อนึ่งที่ 3' ||
      name === 'หม้อนึ่งที่ 4' ||
      name === 'หม้อนึ่งที่ 5'
    ) {
      if (status === true) {
        return (
          <span className="font-sans text-md">
            {/* อยู่ใน Control Range : 2 {'  '} ไม่อยู่ใน Control Range : 2{' '} */}
          </span>
        )
      } else if (status === false) {
        return (
          <span className="font-sans text-xl">
            <LocalFireDepartmentIcon />
            {` กลุ่ม${name}  `}
          </span>
        )
      }
    } else if (
      name === 'ถังกวนที่ 1' ||
      name === 'ถังกวนที่ 2' ||
      name === 'ถังกวนที่ 3' ||
      name === 'ถังกวนที่ 4'
    ) {
      if (status === true) {
        return (
          <span className="font-sans text-md">
            {/* อยู่ใน Control Range : 2 {'  '} ไม่อยู่ใน Control Range : 2{' '} */}
          </span>
        )
      } else if (status === false) {
        return (
          <span className="font-sans text-xl">
            <CoffeeMakerIcon />
            {` กลุ่ม${name}  `}
          </span>
        )
      }
    } else if (
      name === 'หัวหีบที่ 1' ||
      name === 'หัวหีบที่ 2' ||
      name === 'หัวหีบที่ 3' ||
      name === 'หัวหีบที่ 4'
    ) {
      if (status === true) {
        return (
          <span className="font-sans text-md">
            {/* อยู่ใน Control Range : 2 {'  '} ไม่อยู่ใน Control Range : 2{' '} */}
          </span>
        )
      } else if (status === false) {
        return (
          <span className="font-sans text-xl">
            <CompressIcon />
            {` กลุ่ม${name}  `}
          </span>
        )
      }
    } else {
      return <span className="font-sans text-xl"></span>
    }
  }

  //console.log('summarize', summarizes)
  return isLoading ? (
    <div>
      {_.map(summarizes?.arr, (sm, index) => {
        if (!sm?.expression) {
          return (
            <div key={index}>
              <div className="my-2 mx-2 text-lg font-bold">
                {`เซนเซอร์ที่เกี่ยวข้องกับ ${sm?.variable?.name}`}
              </div>
              <div className="grid   w-full bg-gray-200 rounded-sm ">
                <div className="grid-cols-2 px-4 py-2 w-full ">
                  {_.map(sm?.varDetail, (varDetail, index) => (
                    <div>
                      <div className="flex gap-4 my-3 align-center items-center  justify-between ">
                        {sm?.group ? (
                          <h2 className="font-bold">
                            {iconSelect(varDetail?.groupName, false)}

                            {/* <span className="font-sans text-md"> {countStatus(varDetail?.groupName)}</span> */}
                            <span className="ml-3">
                              {_.map(selectStation?.arr, (st) => {
                                return _.map(st?.sensors, (ss) => {
                                  if (ss?.name === varDetail?.groupName) {
                                    const status =
                                      ss?.booleanStatus === true
                                        ? 'กำลังทำงาน'
                                        : 'ไม่ทำงาน'
                                    return (
                                      <span>
                                        <Badge
                                          w={auto}
                                          h={auto}
                                          variant="solid"
                                          fontSize="1em"
                                          colorScheme={
                                            status === 'กำลังทำงาน'
                                              ? 'green'
                                              : 'red'
                                          }
                                        >
                                          {status}{' '}
                                        </Badge>
                                        <Badge
                                          className="ml-2 font-thin text-2xs"
                                          colorScheme="blue"
                                          variant="subtle"
                                        >
                                          {/* {status === 'กำลังทำงาน'
                                            ? 'กำลังทำนาย'
                                            : 'ไม่ทำนาย'}{' '} */}
                                        </Badge>
                                      </span>
                                    )
                                  }
                                })
                              })}
                            </span>
                          </h2>
                        ) : (
                          <h2 className="font-sans text-xl font-bold">
                            {'เซนเซอร์ทั้งหมดของ'} {sm?.variable?.name}
                          </h2>
                        )}

                        <Link
                          to={
                            '/summarize/detail/graphdetail/' +
                            sm?._id +
                            '/' +
                            varDetail._id
                          }
                        >
                          <Button size="sm" colorScheme="blue">
                            รายละเอียด
                          </Button>
                        </Link>
                      </div>
                      <div className="grid grid-cols-1 gap-x-4 gap-y-1 lg:grid-cols-4 md:gid-cols-3 mr-6 mb-4 ">
                        {/* {iconSelect(varDetail?.groupName, true)} */}
                        {/* <StatusRangeControl
                          staticData={varDetail}
                          stations={stations}
                          name={varDetail?.groupName}
                        />
                        <h1></h1>
                        <h1></h1>
                        <h1></h1> */}

                        {_.map(
                          varDetail?.attribute,
                          (eachAttribute, attIndex) => {
                            const foundedStation = _.find(
                              stations?.arr,
                              (eachStation) =>
                                eachStation?._id === eachAttribute?.station
                            )
                            const foundSensor = _.find(
                              foundedStation?.sensors,
                              (each) => each?._id === eachAttribute?.sourceId
                            )

                            return (
                              <div key={attIndex}>
                                <NumberBoxs
                                  graphLabel={eachAttribute?.sourceName}
                                  staticData={
                                    foundSensor?.numericStatus ||
                                    foundSensor?.booleanStatus
                                  }
                                  stationData={foundedStation}
                                  selectedSensor={foundSensor}
                                  unit={foundSensor?.valueUnit}
                                  useThresholdAlarm={true}
                                  size="sm"
                                  link={
                                    '/summarize/detail/sensor/' +
                                    sm?._id +
                                    '/' +
                                    varDetail._id +
                                    '/' +
                                    eachAttribute?.sourceId
                                  }
                                />
                              </div>
                            )
                          }
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        }
      })}
    </div>
  ) : (
    <SpinnerLoading />
  )
}
