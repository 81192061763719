import React, { useState, useEffect } from 'react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import * as actions from '../../redux/actions/'
import { useForm } from 'react-hook-form'
import _, { find } from 'lodash'
import dayjs from 'dayjs'
import DisplayDetail from './component/DisplayDetail'
import DisplayComponentPredict from './component/DisplayComponentPredict'
import { useTranslation } from 'react-i18next'
import { Button } from '@chakra-ui/react'

// Is Summarize Page of Production.
export default function PredictionDetail() {
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  //const [isLoading, setIsLoading] = useState(false)
  const summarizes = useSelector((state) => state.summarize)
  const stations = useSelector((state) => state.station)
  const formInput = useSelector((state) => state.formInput)
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [startTime, setStartTime] = useState(dayjs().format('YYYY-MM-DD'))
  const [endTime, setEndTime] = useState(
    dayjs(startTime).subtract(1, 'month').format('YYYY-MM-DD')
  )

  const findStation = (id) => {
    const data = _?.find(stations?.arr, (each) => each?._id === id)
    return data
  }

  useEffect(() => {
    if (isFetchNewOne) {
      dispatch(actions.summarizeAll())
      dispatch(actions.stationAll())
      dispatch(actions.formInputSummarize(endTime, startTime)).then(() =>
        setIsLoading(true)
      )
      setisFetchNewOne(!isFetchNewOne)
    }
    return () => {}
  }, [params, isFetchNewOne])

  useEffect(() => {
    if (isFetchNewOne === false)
      setTimeout(() => {
        setisFetchNewOne(!isFetchNewOne)
      }, 20000)
    return () => {}
  }, [isFetchNewOne])

  const findData = (id) => {
    const varStation = findStation('641933db73942c001101cb32')
    if (id === '6555dbcf88676d55586ada5a') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '6549bcbd956d160012355d59') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '655472c2de434133acd27176') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '655473b5de434133acd271f9') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '65781176ff1bdc001256a060') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '655473d5de434133acd27212') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else if (id === '659eb111bde8bf4e544f25cf') {
      return _.find(varStation?.variables, (each) => each?._id === id)
    } else {
      return null
    }
    //console.log('selectvariable', data)
    //console.log('station?.variable', station?.variables)
    //setSelectVariable(data)
  }

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การสรุปผลการดำเนินงาน
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              รายการสรุป Production{' '}
            </h2>
          </div>
        </div>

        <div className="flex justify-between my-2">
          <Link to={'/summarize'}>
            <Button variant="outline" size="sm">
              กลับ
            </Button>
          </Link>
        </div>
        <div className="flex flex-wrap p-4 w-full bg-gray-200 rounded-sm">
          <DisplayDetail
            oilLossAll={formInput?.arr[0]}
            yieldAll={formInput?.arr[1]}
            oilLoss={findData('6555dbcf88676d55586ada5a')}
            ffb={findData('6549bcbd956d160012355d59')}
            cpo={findData('65781176ff1bdc001256a060')}
            yieldCpo={findData('659eb111bde8bf4e544f25cf')}
            fiberLoss={findData('655472c2de434133acd27176')}
            decanterLoss={findData('655473b5de434133acd271f9')}
            wasteLoss={findData('655473d5de434133acd27212')}
          />
        </div>
        <div>
          <DisplayComponentPredict
            summarizes={summarizes}
            stations={stations}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
