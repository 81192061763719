import React, { Component, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import dayjs from 'dayjs'

export default function FuelUnit({ valueFuelUsed }) {
  const [tickAmount, setTickAmount] = useState(32)
  const [valueFormat, setValueFormat] = useState('DD MMM')
  //console.log('value Fuel Use', valueFuelUsed)

  const series = [
    {
      name: 'ปริมาณเชื้อเพลิงที่ใช้',
      type: 'column',
      data: valueFuelUsed,
    },
  ]
  const options = {
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },

    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => dayjs(value).locale('th').format(valueFormat),
      },
      tickAmount,
    },
    yaxis: [
      {
        title: {
          text: 'ปริมาณเชื้อเพลิงที่ใช้',
        },
      },
    ],
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  )
}
